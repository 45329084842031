// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authorization-index-mdx": () => import("./../../../src/pages/authorization/index.mdx" /* webpackChunkName: "component---src-pages-authorization-index-mdx" */),
  "component---src-pages-deployment-introduction-mdx": () => import("./../../../src/pages/deployment/introduction.mdx" /* webpackChunkName: "component---src-pages-deployment-introduction-mdx" */),
  "component---src-pages-deployment-method-1-mdx": () => import("./../../../src/pages/deployment/method-1.mdx" /* webpackChunkName: "component---src-pages-deployment-method-1-mdx" */),
  "component---src-pages-deployment-method-2-mdx": () => import("./../../../src/pages/deployment/method-2.mdx" /* webpackChunkName: "component---src-pages-deployment-method-2-mdx" */),
  "component---src-pages-deployment-method-3-mdx": () => import("./../../../src/pages/deployment/method-3.mdx" /* webpackChunkName: "component---src-pages-deployment-method-3-mdx" */),
  "component---src-pages-development-anatomy-of-a-kope-activity-mdx": () => import("./../../../src/pages/development/anatomy-of-a-kope-activity.mdx" /* webpackChunkName: "component---src-pages-development-anatomy-of-a-kope-activity-mdx" */),
  "component---src-pages-development-introduction-to-activities-mdx": () => import("./../../../src/pages/development/introduction-to-activities.mdx" /* webpackChunkName: "component---src-pages-development-introduction-to-activities-mdx" */),
  "component---src-pages-development-kope-development-workflow-mdx": () => import("./../../../src/pages/development/kope-development-workflow.mdx" /* webpackChunkName: "component---src-pages-development-kope-development-workflow-mdx" */),
  "component---src-pages-development-supported-parameter-types-mdx": () => import("./../../../src/pages/development/supported-parameter-types.mdx" /* webpackChunkName: "component---src-pages-development-supported-parameter-types-mdx" */),
  "component---src-pages-help-contact-mdx": () => import("./../../../src/pages/help/contact.mdx" /* webpackChunkName: "component---src-pages-help-contact-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-quick-start-index-mdx": () => import("./../../../src/pages/quick-start/index.mdx" /* webpackChunkName: "component---src-pages-quick-start-index-mdx" */),
  "component---src-pages-setup-download-kope-nuget-packages-mdx": () => import("./../../../src/pages/setup/download-kope-nuget-packages.mdx" /* webpackChunkName: "component---src-pages-setup-download-kope-nuget-packages-mdx" */),
  "component---src-pages-setup-test-your-setup-mdx": () => import("./../../../src/pages/setup/test-your-setup.mdx" /* webpackChunkName: "component---src-pages-setup-test-your-setup-mdx" */),
  "component---src-pages-setup-visual-studio-setup-mdx": () => import("./../../../src/pages/setup/visual-studio-setup.mdx" /* webpackChunkName: "component---src-pages-setup-visual-studio-setup-mdx" */)
}


import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

const Content = () => (
  <>
    <p>
      This guidance remains a work in progress.<br />
      If you feel it can be improved, extended or clarified, please contact us.
    </p>
  </>
);

const links = {
  firstCol: [
    { href: 'https://kope.ai', linkText: 'KOPE Homepage' },
    { href: 'https://www.matterlab.co', linkText: 'A Matterlab Product' },
  ],
};

const CustomFooter = () => <Footer links={links} Content={Content} />;

export default CustomFooter;
